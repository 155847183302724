.blur-background {
  /* backdrop-filter: blur(8px); */
  background-color: rgba(0, 0, 0, 0.3);
}

.ql-editor {
  min-height: 350px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 4px;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  padding: 4px 4px;
  color: #000;
  border-bottom: 1px solid #e6e6e6;
  /* border-radius: 50%; */
  transition: background-color 0.3s;
  /* background-color: #080606; */
}

.pagination li.active {
  /* background-color: #00000030; */
  color: #000;
  border-bottom: 1px solid #000;
}

.pagination li:hover {
  border-bottom: 1px solid #000;
  color: black;
}

.container {
  --uib-size: 35px;
  --uib-color: #a36857;
  --uib-speed: 1.2s;
  --uib-bg-opacity: 0.1;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  will-change: transform;
  overflow: visible;
}

.car {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 25, 75;
  stroke-dashoffset: 0;
  animation: travel var(--uib-speed) linear infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
}

.track {
  fill: none;
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
}

@keyframes travel {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -100;
  }
}
